<template>
  <div>
    <appProjectList userType="corporate_staff"></appProjectList>
  </div>
</template>

<script>
import appProjectList from "../../components/project/ProjectList";
export default {
  name: "ProjectList",
  components: {
    appProjectList,
  },
  data() {
    return {
      roleIds: [6, 7],
    };
  },
};
</script>
